import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { AllTypeInstanceListContainer } from "@capactio/react-components";
import Page from "../layout/Page";
import { loadRuntimeConfig } from "../config/runtime";

function TypeInstances() {
  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/typeinstances">TypeInstances</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  const { queryRefetchIntervalMS } = loadRuntimeConfig();

  const onDeleteTypeInstance = () => {
    window.location.reload();
  };

  return (
    <Page breadcrumb={breadcrumb} title="TypeInstances List">
      <AllTypeInstanceListContainer 
        refetchInterval={queryRefetchIntervalMS}
        onDeleteAction={onDeleteTypeInstance}
      />
    </Page>
  );
}

export default TypeInstances;
