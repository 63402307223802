import { Layout, Menu as AntMenu } from "antd";
import React, { useState } from "react";
import { ApartmentOutlined, AppstoreOutlined, AppstoreAddOutlined, 
  AuditOutlined, ClusterOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

const { Item } = AntMenu;
const { Sider } = Layout;

function Menu() {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const links = [
    {
      name: "Hub",
      route: "/hub/interface-groups",
      icon: <ApartmentOutlined />,
    },
    {
      name: "Actions",
      route: "/actions",
      icon: <AppstoreAddOutlined />,
    },
    {
      name: "Composed Actions",
      route: "/composed-actions",
      icon: <ClusterOutlined />,
    },
    /* {
      name: "Pipelines",
      route: "/workflow",
      icon: <ContainerOutlined />,
    }, */
    {
      name: "TypeInstances",
      route: "/typeinstances",
      icon: <AppstoreOutlined />,
    },
    {
      name: "Policy",
      route: "/policy",
      icon: <AuditOutlined />,
    },
    {
      name: "Role",
      route: "/role",
      icon: <UsergroupAddOutlined />,
    },
  ];

  const currentRoute = links.find((elem) =>
    location.pathname.startsWith(elem.route)
  );

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed) => setCollapsed(collapsed)}
      className="site-layout-background"
    >
      <AntMenu mode="inline" selectedKeys={[currentRoute?.route ?? ""]}>
        {links.map(({ route, name, icon }) => (
          <Item key={route} icon={icon}>
            <Link to={route}>{name}</Link>
          </Item>
        ))}
      </AntMenu>
    </Sider>
  );
}

export default Menu;
